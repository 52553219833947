:root {

    --white: #fff;
    --white-1: rgb(236, 236, 236);
    --white-2: rgb(216, 216, 216);

    --color: var(--white);
    --background: #0f0f0f;
    --background-darker: #0a0a0a;
    --background-lighter: #1d1d1d;


    --font-small: 0.8em;
    --font-ms: 0.9em;
    --font-medium: 1.1em;
    --font-big: 1.5em;
    --font-xl: 2em;
    --font-xxl: 2.2em;
    --font-xxxl: 2.5em;

    --color-1: #0077B6;
    --color-2: #0085cc;
    --color-3: #00B4D8;
    --color-4: #90E0EF;
    --color-5: #dcf9ff;

    --navbar-text-color: rgb(255, 255, 255);
    --navbar-background: rgb(20, 20, 20);
    --navbar-small-background: rgba(20, 20, 20, 0.90);

    --navbar-link-hover-border:  2px solid var(--color-3);
    --navbar-active-link-color: var(--color-2);
    --navbar-active-link-border: 2px solid var(--color-2);

    --button-background: var(--color-2);
    --button-hover-background: var(--color-3);

    --button-outline-color: var(--color-2);
    --button-outline-hover: 2px solid var(--color-2);

    --section-header-border-bottom: 2px solid var(--color-3);

    --progressbar-color: var(--color-3);
}

* {
    box-sizing: border-box; 
}

html {
    font-size: 62.5%;
}

html, body {
    padding: 0;
    margin: 0;
    font-family: 'Montserrat', serif;
    background: #0f0f0f;
    background: var(--background);
    color: #fff;
    color: var(--color);
    font-size: 1.1em;
    font-size: var(--font-medium);
}

ul {
    -webkit-margin-before: 0em;
            margin-block-start: 0em;
    -webkit-margin-after: 0em;
            margin-block-end: 0em;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
    -webkit-padding-start: 0px;
            padding-inline-start: 0px;
  }

.background-dark {
    background: #0a0a0a;
    background: var(--background-darker);
}
  
.navbar {
    background: rgb(20, 20, 20);
    background: var(--navbar-background);
    max-height: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 99;
    color: rgb(255, 255, 255);
    color: var(--navbar-text-color);
    transition: max-height 0.2s ease;
}

.navbar.small {
    max-height: 50px;
    background: rgba(20, 20, 20, 0.90);
    background: var(--navbar-small-background);

}

.navbar-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1800px;
}

.navbar-logo {
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    color: rgb(255, 255, 255);
    color: var(--navbar-text-color);
}

.nav-menu {
    display: flex;
    text-align: center;
    width: 60vw;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    list-style-type: none;
}

.nav-item {
    cursor: pointer;
    padding: 12px;
    border-bottom: 2px solid transparent;
    transition: all .15s ease-in;
}

.nav-item:hover {
    border-bottom: 2px solid #00B4D8;
    border-bottom: var(--navbar-link-hover-border);
}
.nav-item.active {
    color: #0085cc;
    color: var(--navbar-active-link-color);
    border-bottom: 2px solid #0085cc;
    border-bottom: var(--navbar-active-link-border);
}

.nav-links {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}


.main-container {
    width: 100%;
    height: calc(100vh - 80px);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    display: flex;
}

.section-container {
    width: 100%;
    padding: 2.5em 25px;
    /* height: 100vh; */
}


.home-container {
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    top: 0;
}

.home-container h1 {
    font-weight: 600;
    font-size: 2.5em;
    font-size: var(--font-xxxl);
    margin: 0;
}

.home-container p {
    margin-top: 0;
    margin-bottom: 25px;
}


.home-info {
    max-width: 800px;
    margin: 200px;
    padding: 0 5vh;
}

.btn {
    padding: 10px 35px;
    display: inline-block;
    border-radius: 1.5em;
    border: 2px solid transparent;
    cursor: pointer;
    margin: 5px 10px;
    transition: all .15s ease-in;
}

.btn-primary {
    background: #0085cc;
    background: var(--button-background);
}

.btn-primary:hover {
    background: #00B4D8;
    background: var(--button-hover-background);
}

.btn-outline {
    background-color: transparent;
    border: 2px solid #0085cc;
    border: var(--button-outline-hover);
    color: #0085cc;
    color: var(--button-outline-color);
}

.btn-outline:hover {
    background-color: #0085cc;
    background-color: var(--button-background);
    border: 2px solid #0085cc;
    border: var(--button-outline-hover);
    color: #fff;
    color: var(--color);
}

.home-scroll-icon {
    font-size: 1.5em;
    font-size: var(--font-big);
    position: absolute;
    bottom: 0;
    left: 25%;
}

.home-bottom {
    position: absolute;
    bottom: 50px;
    width: 50px;
    height: 50px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    cursor: pointer;
}

.anim-hover-2s {
    -webkit-animation: hoveranim 1s infinite alternate ease-in-out;
            animation: hoveranim 1s infinite alternate ease-in-out;
}

.section-header {
    font-size: 2.2em;
    font-size: var(--font-xxl);
    text-align: center;
    display: inline-block;
    padding-bottom: 0.25em;
    border-bottom: 2px solid #00B4D8;
    border-bottom: var(--section-header-border-bottom);
}

.section-header-holder {
    width: 100%;
    padding-bottom: 0.5em;
    display: flex;
    justify-content: center;
}

.skills-holder {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.skill {
    width: 500px;
    padding: 2rem 2rem;
    margin: 15px;
    background-color: #0f0f0f;
    border-radius: 5px;
    cursor: default;
    box-shadow: -5px -5px 25px 2px rgba(0, 0, 0, 0.75);
    transition: all 0.3s ease-in-out;
}

.skill:hover {
    scale: 1.01;
    box-shadow: 5px 5px 25px 2px rgba(0, 0, 0, 0.75);
}

.skill-name {
    margin: 0 5px;
}

.skill-desc {
    color: rgb(196, 196, 196);
    margin-top: 10px;
}

.font-small {
    font-size: 0.8em;
    font-size: var(--font-small);
}

.float-right {
    float: right;
}

.divider {
    height: 15px;
    width: 3px;
    border-radius: 100px;
    display: inline-block;
    margin: 0 0.3rem;
    background: #00B4D8;
    background: var(--progressbar-color);
}

.progressbar {
    background: rgba(255,255,255,0.05);
    justify-content: flex-start;
    border-radius: 100px;
    align-items: center;
    position: relative;
    bottom: 0;
    display: flex;
    height: 10px;
    width: 100%;
}

.flex-column {
    flex-direction: column!important;
}

.full-width {
    width: 100%!important;
}

.display-flex {
    display: flex!important;
}

.align-self-center {
    align-self: center!important;
}

.justify-space-between {
    justify-content: space-between!important;
}

.align-content-center {
    align-content: center;
}

.align-start {
    align-items: flex-start;
}

.align-center {
    align-items: center;
}

.justify-center {
    justify-content: center!important;
}

.flex-wrap {
    flex-wrap: wrap;
}

.full-width {
    width: 100%;
}

.flex-row {
    flex-direction: row!important;
}
  
.progress-value {
    border-radius: 100px;
    background: #00B4D8;
    background: var(--progressbar-color);
    height: 5px;
    width: 50%;
}

.width-auto {
    width: auto;
}

.color-red {
    color:rgb(255, 46, 46);
}

.color-1 {
    color: #0077B6;
    color: var(--color-1);
}

.color-2 {
    color: #0085cc;
    color: var(--color-2);
}

.color-3 {
    color: #00B4D8;
    color: var(--color-3);
}

.color-4 {
    color: #90E0EF;
    color: var(--color-4);
}

.color-5 {
    color: #dcf9ff;
    color: var(--color-5);
}

.carousel {
    width: 100%;
    height: 500px;
    margin-bottom: 3em;

}

.carousel-name {
    font-size: 1.5em;
    font-size: var(--font-big);
    margin-bottom: 15px;
    width: 100%;
}


.slider-img {
    height: 500px;
    margin: 0 0.5rem;
    /* transition: all .3s ease-in-out; */
}

.dummy-element {
    display: none;
}

.slider-img:hover {
    /* box-shadow: 0px 0px 20px 5px black; */
}

.flickity-prev-next-button {
    top: 50%;
    width: 50px;
    height: 100%;
    border-radius: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    color: rgb(228, 228, 228);
    opacity: 0.8;
}

.flickity-prev-next-button.previous {
    left: 0;
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
    color: rgb(228, 228, 228);

}

.flickity-prev-next-button.next {
    right: 0;
    background: linear-gradient(-90deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
    color: rgb(228, 228, 228);


}

.flickity-button:disabled {
    opacity: .3;
    cursor: auto;
    pointer-events: none;
    background: none;
}

.flickity-button {
    position: absolute;
    background: hsla(0,0%,100%,.75);
    border: none;
    color: #333;
    transition: all .15s ease-in-out;
}

.flickity-page-dots {
    top: -25px;
    bottom: unset;
    right: 0;
    text-align: unset;
    width: unset;
}

.flickity-page-dots .dot {
    background: #dcdcdc;
    border-radius: unset;
    width: 20px;
    height: 5px;
    transition: all .15s ease-in-out;
}

.flickity-page-dots .dot:hover {
    opacity: 0.8;
}

.flickity-button:active {
    opacity: .9;
}

.flickity-button:hover {
    opacity: 0.9;
    cursor: pointer;
}

.exp-list {
    list-style-type: none;
}

.bold {
    font-weight: bold;
}

.exp-list-dot {
    position: relative;
    display: block;
    border-radius: 50%;
    height: 0.5rem;
    width: 0.5rem;
    background-color: #90E0EF;
    background-color: var(--color-4);
    top: calc(0.4rem + 0.8rem);
    margin-bottom: calc(1rem * -1);
}

.exp-list-element--path {
    position: relative;
}


.exp-list-element {
    padding: 1.2rem 1.2rem;
    display: flex;
}

.exp-list-dot::before {
    content: "";
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-55%);
            transform: translateX(-55%);
    top: 0.75rem;
    height: 4.5rem;
    width: 0.18rem;
    background-color: #dcf9ff;
    background-color: var(--color-5);
}

.no-before::before{
    content:none;
}

.exp-list-position-1 { 
    font-weight: 700;
}

.exp-list-position-2 {
    font-size: 0.9em;
    font-size: var(--font-ms);
    color: rgb(236, 236, 236);
    color: var(--white-1);
}

.exp-list-position-3 {
    font-size: 0.8em;
    font-size: var(--font-small);
    color: rgb(216, 216, 216);
    color: var(--white-2);
}

.exp {
    width: auto;
    border-radius: 5px;
    margin: 2rem;
    padding: 2rem;
    background: #0f0f0f;
    background: var(--background);
}

.form {
    flex-grow: 1;
    display: flex;
    flex-flow: column;
}

.form-input {
    flex: 1 1 auto;
    padding: 0.75rem;
    margin: 0.5rem;
    background: #0f0f0f;
    background: var(--background);
    border: none;
    color: white;
    border: 2px solid transparent;
}

.row {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

input:focus, textarea:focus, input:focus-visible, textarea:focus-visible {
    background-color: rgb(3, 3, 3);
    color: white;
    border-color: inherit;
    box-shadow: none;
    outline: none;
    border: 2px solid #0077B6;
    border: 2px solid var(--color-1);
}

textarea {
    min-height: 25em;
    height: 436px;
}

.footer-wrapper {
    padding-top: 2em;
    display: flex;
}

.vertical-divider {
    width: 0.2em;
    margin: 0 2em;
    background-color: #1d1d1d;
    background-color: var(--background-lighter);
}

.horizontal-divider {
    display: none;;
}

.full-image-cover {
    width: 100%;
    height: 100%;
    z-index: 998;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.9);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
}

.full-image {
    height: 80vh;
    width: auto;
    
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 999;
    
    -webkit-transform: translate(-50%, -50%);
    
            transform: translate(-50%, -50%);
}

.background-image {
    background-image: url(/static/media/bg_c_1.ba285a84.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    width: 100%;
}

.close-button {
    position: absolute;
    top: 50px;
    right: 50px;
    font-size: 2em;
    cursor: pointer;
}

*::-webkit-input-placeholder {
    font-weight: bold;
    opacity: .5;
    color: #fff;
    color: var(--white);
    font-weight: 100;
    font-family: 'Montserrat', serif;
    opacity: 0.75;
}

*::placeholder {
    font-weight: bold;
    opacity: .5;
    color: #fff;
    color: var(--white);
    font-weight: 100;
    font-family: 'Montserrat', serif;
    opacity: 0.75;
}

@-webkit-keyframes hoveranim {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }
}

@keyframes hoveranim {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }
}

@media screen and (max-width: 1600px) {
    .background-image {
        width: 150%;
        margin-right: -5vw;
    }
}

@media screen and (max-width: 1400px) {
    .background-image {
        margin-right: -15vw;
    }
    .home-info {
        margin: 100px;
    }
}

@media screen and (max-width: 1250px) {
    .background-image {
        margin-right: -25vw;
    }
    .home-info {
        margin-left: 10px;
        margin-right: 10px;
    }
}



@media screen and (max-width: 960px) {
    .navbar.small {
        max-height: 80px;
    }

    .vertical-divider {
        display: none;
    }

    .footer-wrapper {
        flex-wrap: wrap;
    }


    .horizontal-divider {
        display: block;
        height: 0.2em;
        margin: 2em 2em;
        flex-basis: 100%;
        flex-grow: 3;
        background-color: #1d1d1d;
        background-color: var(--background-lighter);
        width: 100%;
    }

    .background-image {
        display: none;
    }
    
    .home-container {
        height: auto;
        position: relative;
    }

    .main-container::after{
        content: '';
        clear: both;
    }

    .main-container {
        overflow: visible;
        height: auto;
        margin-bottom: 5vh;
    }

    .home-bottom {
        margin-bottom: -10vh;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: calc(100vh - 80px);
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        -webkit-margin-before: 0;
                margin-block-start: 0;
        -webkit-margin-after: 0;
                margin-block-end: 0;
        -webkit-margin-start: 0;
                margin-inline-start: 0;
        -webkit-margin-end: 0;
                margin-inline-end: 0;
        -webkit-padding-start: 0;
                padding-inline-start: 0;
        transition: all 0.5s ease;
    }

    .nav-menu.small {
        top: 80px;
        height: calc(100vh - 80px);
    }

    /* .main-container {
        height: auto;
    } */

    .slider-img, .carousel {
        height: 300px;
    }

    .nav-menu.active {
        background: rgba(20, 20, 20, 0.90);
        background: var(--navbar-small-background);
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        background-color: #fff;
        color: #242424;
        border-radius: 0;
    }

    .menu-icon {
        display: inline-block;
        cursor: pointer;
        box-sizing: border-box;
        text-align: center;
        line-height: 50px;
        background: #1f1f1f;
        height: 50px;
        width: 50px;
        margin: 10px;
        border-radius: 100%;
    }

    .home-info {
        margin: 5vh 0 ;
        max-width: 100vw;
    }
    
    .section-container {
        padding: 2.5em 25px;
        /* height: 100vh; */
    }

    .exp {
        margin: 2rem 0;
    }

    .skill {
        margin: 2rem 0;
    }
}
